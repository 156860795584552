import {
  Component,
  ElementRef,
  Inject,
  HostListener,
  OnInit,
  ViewChild,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { environment } from '../../../environments/handyhand/environment.dk';
import { isPlatformBrowser } from '@angular/common';
import {
  AnimationEvent,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Config } from '@app/config/config';

import { User } from '@models/user.model';
import { Router, RouterLink } from '@angular/router';

import { JsonLdPipe } from '@app/pipes/json-ld-pipe';
import { TranslatePipe } from '@app/pipes/translate.pipe';

import { AuthService } from '@services/http/auth.service';
import { ExternalService } from '@app/services/http/external.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { SEOService } from '@services/logic/seo.service';
import { StorageService } from '@services/logic/storage.service';

import { MatIconModule } from '@angular/material/icon';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgOptimizedImage } from '@angular/common';
import { CommonModule } from '@angular/common';

// Components
import { BraggingLineComponent } from '@components/seoPageElements/bragging-line/bragging-line.component';
import { FooterComponent } from '@components/shared/footer/footer.component';
import { HowItWorksComponent } from '@components/seoPageElements/howItWorks/howItWorks.component';
import { LandingFaqComponent } from '@app/pages/landing-page/landingFaq/landingFaq.Component';
import { TaskExamplesLp } from '@app/pages/landing-page/taskExamples/taskExamples-lp';
import { PlatformActivityComponent } from '@components/platformActivity/platform-activity.component';
import { ServiceOptionsComponent } from '@app/pages/landing-page/serviceOptions/serviceOptionsComponent';
import { WhyHandyhandComponent } from '@components/seoPageElements/whyPinployComponent/whyHandyhandComponent';
import { GenericButtonWithArrow } from '@components/shared/genericButtonWithArrow/genericButtonWithArrow';
import { MeetHandyhandsComponent } from '@components/seoPageElements/meet-handyhands/meetHandyhands.component';

@Component({
  selector: 'app-landing-page',
  imports: [
    FooterComponent,
    JsonLdPipe,
    TranslatePipe,
    NgOptimizedImage,
    MatIconModule,
    PlatformActivityComponent,
    BraggingLineComponent,
    RouterLink,
    CarouselModule,
    HowItWorksComponent,
    WhyHandyhandComponent,
    LandingFaqComponent,
    TaskExamplesLp,
    ServiceOptionsComponent,
    GenericButtonWithArrow,
    MeetHandyhandsComponent,
    CommonModule,
  ],
  providers: [
    AuthService,
    SEOService,
    StorageService,
    JsonLdPipe,
    TranslatePipe,
    ExternalService,
    SsrCookieService,
  ],
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  animations: [
    trigger('animateText', [
      state(
        'hidden',
        style({
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          opacity: 1,
        }),
      ),
      transition('hidden <=> visible', [animate('0.5s ease')]),
    ]),
  ],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  @ViewChild('div', { static: true }) _div?: ElementRef;
  public isBrowser: boolean = false;
  users: User[] = [];
  innerWidth: number = 481;
  schema: any;
  private readonly heroImages = [
    `${environment.cloudFrontImgUrl}/private/lp/header_1.webp`,
    `${environment.cloudFrontImgUrl}/private/lp/header_2.webp`,
    `${environment.cloudFrontImgUrl}/private/lp/header_3.webp`,
    `${environment.cloudFrontImgUrl}/private/lp/header_4.webp`,
    `${environment.cloudFrontImgUrl}/private/lp/header_5.webp`
  ];
  public moving_img = this.heroImages[0];
  public cleaning_img = this.heroImages[1];
  public carpenter_img = this.heroImages[2];
  public garden_img = this.heroImages[3];
  public party_img = this.heroImages[4];

  public staticHeroText = this.t.transform('PAGES.LANDING.LP1.TITLE_1');
  public dynamicHeroText = this.t.transform('PAGES.LANDING.LP1.TITLE_1_1');
  public dynamicSubText1 = this.t.transform('PAGES.LANDING.LP1.SUB_1_1');
  public dynamicSubText2 = this.t.transform('PAGES.LANDING.LP1.SUB_1_2');
  public dynamicSubText3 = this.t.transform('PAGES.LANDING.LP1.SUB_1_3');
  public dynamicHeroText4 = this.t.transform(
    'PAGES.LANDING.LP1.TITLE_1_1_LAST',
  );
  public currentSubText: string[] = [
    this.dynamicSubText1,
    this.dynamicSubText2,
    this.dynamicSubText3,
  ];
  private intervalId: any; // reduce memory leaks
  private timeOutId: any; // reduce memory leaks
  dynamicHeroImg = this.moving_img;
  currentState = 'hidden';
  intervals = 2;
  isAuth = false;

  constructor(
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private t: TranslatePipe,
    private router: Router,
    private seoService: SEOService,
    private storage: StorageService,
    public externalService: ExternalService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.preloadHeroImages();
    }
  }

  readonly items: number[] = Array(1)
    .fill(1)
    .map((item, index) => item + index);

  ngOnInit() {
    this.redirectOnUserRole();
    this.browserRendered();
    this.injectOrgSchema();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  browserRendered() {
    this.seoService.setSitelinksSearchboxSchema(this.router.url);
    if (this.isBrowser) {
      this.isAuth = this.isAuthenticated();
      this.innerWidth = window.innerWidth;
      if (this.innerWidth > 480) {
        this.changeHeroTextInter();
      }
    }

    this.seoService.setPageMeta({
      metaTitle: this.t.transform('PAGES.LANDING.META_TITLE'),
      metaDesc: this.t.transform('PAGES.LANDING.META_DESC'),
      canonicalUrl: `${environment.domain}${this.router.url}`,
      img: `${environment.cloudFrontImgUrl}/private/lp/handyhand_og_img-min.png`,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.isBrowser) {
      this.innerWidth = window.innerWidth;
    }
  }

  changeText() {
    // instead change the state to hidden first for fade away animation
    this.currentState = 'hidden';
  }

  animationFinished(event: AnimationEvent) {
    if (event.fromState === 'void' && event.toState === 'hidden') {
      /**
       * This block executes when the component is rendered initially.
       * Change the state to visible once the component has been initialized
       * to play the animation
       */
      this.currentState = 'visible';
    } else if (event.fromState === 'visible' && event.toState === 'hidden') {
      /**
       * Once the previous text fades, perform the text change logic
       * and then change state to visible to play the animation
       */
      this.currentState = 'visible';
    }
  }

  changeHeroTextInter() {
    const heroText1 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_1');
    const subText1_1 = this.t.transform('PAGES.LANDING.LP1.SUB_1_1');
    const subText1_2 = this.t.transform('PAGES.LANDING.LP1.SUB_1_2');
    const subText1_3 = this.t.transform('PAGES.LANDING.LP1.SUB_1_3');
    const heroText1_5 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_1_LAST');
    const img_1 = this.moving_img;
    const texts1: [any, any, any, any, any, any] = [
      heroText1,
      subText1_1,
      subText1_2,
      subText1_3,
      img_1,
      heroText1_5,
    ];

    const heroText2 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_2');
    const subText2_1 = this.t.transform('PAGES.LANDING.LP1.SUB_2_1');
    const subText2_2 = this.t.transform('PAGES.LANDING.LP1.SUB_2_2');
    const subText2_3 = this.t.transform('PAGES.LANDING.LP1.SUB_2_3');
    const heroText2_5 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_2_LAST');
    const img_2 = this.garden_img;
    const texts2: [any, any, any, any, any, any] = [
      heroText2,
      subText2_1,
      subText2_2,
      subText2_3,
      img_2,
      heroText2_5,
    ];

    const heroText3 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_3');
    const subText3_1 = this.t.transform('PAGES.LANDING.LP1.SUB_3_1');
    const subText3_2 = this.t.transform('PAGES.LANDING.LP1.SUB_3_2');
    const subText3_3 = this.t.transform('PAGES.LANDING.LP1.SUB_3_3');
    const heroText3_5 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_3_LAST');
    const img_3 = this.carpenter_img;
    const texts3: [any, any, any, any, any, any] = [
      heroText3,
      subText3_1,
      subText3_2,
      subText3_3,
      img_3,
      heroText3_5,
    ];

    const heroText4 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_4');
    const subText4_1 = this.t.transform('PAGES.LANDING.LP1.SUB_4_1');
    const subText4_2 = this.t.transform('PAGES.LANDING.LP1.SUB_4_2');
    const subText4_3 = this.t.transform('PAGES.LANDING.LP1.SUB_4_3');
    const heroText4_5 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_4_LAST');
    const img_4 = this.cleaning_img;
    const texts4: [any, any, any, any, any, any] = [
      heroText4,
      subText4_1,
      subText4_2,
      subText4_3,
      img_4,
      heroText4_5,
    ];

    const heroText5 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_5');
    const subText5_1 = this.t.transform('PAGES.LANDING.LP1.SUB_5_1');
    const subText5_2 = this.t.transform('PAGES.LANDING.LP1.SUB_5_2');
    const subText5_3 = this.t.transform('PAGES.LANDING.LP1.SUB_5_3');
    const heroText5_5 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_5_LAST');
    const img_5 = `${environment.cloudFrontImgUrl}/private/lp/webp/lp_hero_imgs/square_taking-a-selfie-with-dog-blue-min.webp`;
    const texts5: [any, any, any, any, any, any] = [
      heroText5,
      subText5_1,
      subText5_2,
      subText5_3,
      img_5,
      heroText5_5,
    ];

    const heroText6 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_6');
    const subText6_1 = this.t.transform('PAGES.LANDING.LP1.SUB_6_1');
    const subText6_2 = this.t.transform('PAGES.LANDING.LP1.SUB_6_2');
    const subText6_3 = this.t.transform('PAGES.LANDING.LP1.SUB_6_3');
    const heroText6_5 = this.t.transform('PAGES.LANDING.LP1.TITLE_1_6_LAST');
    const img_6 = this.party_img;
    const texts6: [any, any, any, any, any, any] = [
      heroText6,
      subText6_1,
      subText6_2,
      subText6_3,
      img_6,
      heroText6_5,
    ];

    this.intervalId = setInterval(() => {
      switch (this.intervals) {
        case 1:
          return this.setText(texts1);
        case 2:
          return this.setText(texts2);
        case 3:
          return this.setText(texts3);
        case 4:
          return this.setText(texts4);
        case 5:
          return this.setText(texts5);
        default:
          return this.setText(texts6);
      }
    }, 5000);
  }

  setText(text: [any, any, any, any, any, any]) {
    this.intervals += 1;
    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }
    this.timeOutId = setTimeout(() => {
      this.dynamicHeroText = text[0];
      this.dynamicSubText1 = text[1];
      this.dynamicSubText2 = text[2];
      this.dynamicSubText3 = text[3];
      this.dynamicHeroText4 = text[5];
      this.dynamicHeroImg = text[4];
    }, 500);

    if (this.intervals === 5) {
      this.intervals = 0;
    }
    this.changeText();
    this.currentSubText = [text[1], text[2], text[3]];
  }

  redirectOnUserRole(): Promise<boolean> | void {
    if (this.authService.isAuthenticated() && this.isBrowser) {
      if (this.storage.user.pinployeeOrTaskOwner === 'pp') {
        return this.externalService.goToTasks();
      } else {
        return this.externalService.goToLanding();
      }
    }
  }

  injectOrgSchema() {
    this.schema = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: environment.domain,
      name: environment.appName,
      logo: `${environment.appUrl}/images/logo.png`,
      contactPoint: {
        '@type': 'ContactPoint',
        email: Config.Basic.supportEmail,
        telephone: Config.Basic.supportPhone,
        contactType: 'Customer service',
        url: `${environment.domain}/hjaelp`,
        areaServed: Config.Basic.language.toUpperCase(),
        availableLanguage: ['Danish', 'English'],
      },
    };
  }

  private preloadHeroImages(): void {
    this.heroImages.forEach(imageUrl => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = imageUrl;
      document.head.appendChild(link);
    });
  }
}
