import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { environment } from '../../../../environments/handyhand/environment.dk';
import { StateService } from '@services/logic/state.service';

import { TranslatePipe } from '@app/pipes/translate.pipe';
import { GenericButtonWithArrow } from '@components/shared/genericButtonWithArrow/genericButtonWithArrow';
import { HandyhanderTypeInterface } from '@components/seoPageElements/meet-handyhands/interface/handyhander.type.interface';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    imports: [NgOptimizedImage, TranslatePipe, GenericButtonWithArrow],
    providers: [TranslatePipe, StateService, Router],
    host: { ngSkipHydration: 'true' },
    selector: 'app-meet-handyhanders-component',
    templateUrl: './meetHandyhands.component.html',
    styleUrls: ['./meetHandyhands.component.scss']
})
export class MeetHandyhandsComponent implements OnInit {
  public piggyBank = '/assets/svg/icons/icon-pig.svg';
  public idCard = '/assets/svg/icons/id-card.svg';
  public briefcase = '/assets/svg/icons/briefcase.svg';
  public readonly placeholderImage = '/assets/svg/logo.svg';
  public handyhandPickDesktop = `${environment.cloudFrontImgUrl}/private/lp/handyhand-large_123.webp`;
  public handyhandPickMobile = `${environment.cloudFrontImgUrl}/private/lp/handyhand-smaller_123.webp`;
  public activeHandyhandImg: string = '';
  public starIcon = '/assets/svg/star_vector.svg';
  private _innerWidth: number = 0;

  get innerWidth(): number {
    return this._innerWidth;
  }
  public isMobile: boolean = true;
  public handyhanders: HandyhanderTypeInterface[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
  ) {
    this.isMobile = true;
    this.handyhanders = [
      {
        name: 'Lise',
        imageSrc: 'https://d10a6kin4dgxel.cloudfront.net/private/lp/community-7.webp',
        specialityTranslationKey: 'PAGES.LANDING.HH_SPECIALITY_2',
        userDescriptionKey: 'PAGES.LANDING.HH_DESC_2',
        trustRating: '5.0',
        completionRate: '99,7',
        reviewTranslationKey1: 'PAGES.LANDING.HH_WHAT_OTHERS_SAY1',
        reviewTranslationKey2: 'PAGES.LANDING.HH_WHAT_OTHERS_SAY_2',
        reviewGiven: 73,
        reviewReceived: 73,
        reviewGiver: 'Jan L.',
        badges: [
          { src: this.idCard, alt: 'Digitalt ID' },
          { src: this.briefcase, alt: 'Verificeret telefonummer' },
          { src: this.piggyBank, alt: 'Bankoplysninger' },
        ],
      },
      {
        name: 'Carsten',
        imageSrc: 'https://d10a6kin4dgxel.cloudfront.net/private/lp/community-4.webp',
        specialityTranslationKey: 'PAGES.LANDING.HH_SPECIALITY_1',
        userDescriptionKey: 'PAGES.LANDING.HH_DESC_1',
        trustRating: '4.9',
        completionRate: '100,0',
        reviewTranslationKey1: 'PAGES.LANDING.HH_WHAT_OTHERS_SAY',
        reviewTranslationKey2: 'PAGES.LANDING.HH_WHAT_OTHERS_SAY_1',
        reviewGiver: 'Simon P.',
        reviewGiven: 83,
        reviewReceived: 83,
        badges: [
          { src: this.idCard, alt: 'Digitalt ID' },
          { src: this.briefcase, alt: 'Verificeret telefonummer' },
          { src: this.piggyBank, alt: 'Bankoplysninger' },
        ],
      },
      {
        name: 'Gustav',
        imageSrc: 'https://d10a6kin4dgxel.cloudfront.net/private/lp/community-2.webp',
        specialityTranslationKey: 'PAGES.LANDING.HH_SPECIALITY_3',
        userDescriptionKey: 'PAGES.LANDING.HH_DESC_3',
        trustRating: '5.0',
        completionRate: '96,9',
        reviewTranslationKey1: 'PAGES.LANDING.HH_WHAT_OTHERS_SAY2',
        reviewTranslationKey2: 'PAGES.LANDING.HH_WHAT_OTHERS_SAY_3',
        reviewGiver: 'Lene K.',
        reviewGiven: 32,
        reviewReceived: 32,
        badges: [
          { src: this.idCard, alt: 'Digitalt ID' },
          { src: this.briefcase, alt: 'Verificeret telefonummer' },
          { src: this.piggyBank, alt: 'Bankoplysninger' },
        ],
      },
    ];
  
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateWindowDimensions();
  }

  ngOnInit() {
    this.updateWindowDimensions();
  }

  private updateWindowDimensions() {
    if (isPlatformBrowser(this.platformId)) {
      this._innerWidth = window.innerWidth;
      this.isMobile = this._innerWidth <= 900;
      this.updateActiveHandyhandImg();
    }
  }

  updateActiveHandyhandImg() {
    this.activeHandyhandImg = this.isMobile
      ? this.handyhandPickMobile
      : this.handyhandPickDesktop;
  }
}
