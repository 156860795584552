@if (innerWidth >= 810) {
  <app-platform-activity></app-platform-activity>
}
<div class="landing_container" #div>
  <section class="hero-section">
    <div class="wrapper">
      <div class="header_container">
        <div class="hero-section-text-container">
          <div class="hero-section-text">
            <div class="headline_sub_line_wrap">
              <h1 class="white_text">
                {{ staticHeroText }}
                @if (isBrowser) {
                  <span
                    class="text_hero"
                    [@animateText]="currentState"
                    (@animateText.done)="animationFinished($event)"
                    >{{ dynamicHeroText }}
                    <span class="last_word">
                      {{ dynamicHeroText4 }}
                    </span>
                  </span>
                }
              </h1>
              <p class="sub_header_text">
                {{ 'PAGES.LANDING.SUB_TXT' | translate }}
              </p>
            </div>

            <div class="button_div">
              <app-generic-button-with-arrow
                (onClick)="externalService.goToCreateTask()"
                [label]="'PAGES.LANDING.TO_BUTTON' | translate"
              ></app-generic-button-with-arrow>
              <app-generic-button-with-arrow
                routerLink="/findarbejde"
                [label]="'PAGES.LANDING.PP_BUTTON' | translate"
                [color_string]="'more_info_button'"
              ></app-generic-button-with-arrow>
            </div>
          </div>
        </div>
        <div class="header_image">
          @if (isBrowser) {
            <img
              [@animateText]="currentState"
              (@animateText.done)="animationFinished($event)"
              ngSrc="{{ dynamicHeroImg }}"
              width="350"
              height="370"
              priority
              fetchpriority="high"
              [ngStyle]="{'opacity': currentState === 'visible' ? 1 : 0}"
              sizes="(max-width: 480px) 65vw, (max-width: 1100px) 40vw, 35vw"
              alt="handyman markedsplads deleøkonomi platform find opgaver online i Danmark"
            />
          }
        </div>
      </div>
    </div>
    <!--<app-conversion-stats></app-conversion-stats>-->
    <div class="bragging_line_container">
      <div class="bragging_line_inner">
        <app-bragging-line> </app-bragging-line>
      </div>
    </div>
  </section>

  <app-service-options></app-service-options>
  <app-how-it-works [noButton]="true"></app-how-it-works>
  <app-task-examples-lp [isLandingPage]="true"> </app-task-examples-lp>

  <app-why-handyhand [isLandingPage]="true"></app-why-handyhand>
  <app-meet-handyhanders-component></app-meet-handyhanders-component>
  <app-landing-page-faq [noBorderRadius]="true"> </app-landing-page-faq>

  @if (schema) {
    <div [innerHTML]="schema | jsonLd"></div>
  }
  <app-lp-footer [greyBackground]="true"></app-lp-footer>
</div>
