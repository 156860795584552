<section
  class="task_carousel"
  [ngClass]="{
   whiteBackground: whiteBackground,
   toDashBoardHeight: noTitle, noButton: noButton
  }"
>
  @if(!noTitle) {
  <div class="user-title">
    @if (!isFetchingTasks || headlineIsLoaded) {
    <h2 style="margin-bottom: 20px">{{ 'PAGES.LANDING.TASKS' | translate }}</h2>
    } @else if (isFetchingTasks && !headlineIsLoaded) {
    <h2 class="isLoading">{{ 'PAGES.LANDING.TASKS' | translate }}</h2>
    }
  </div>
  } @if (!isFetchingTasks || headlineIsLoaded) {
  <div
    class="categoriesFilter_container max_width_page_component"
    [ngClass]="{ center_categories: isLandingPage, max_width_page_component: isToDashboard}"
  >
    <div class="categoriesFilter" [ngClass]="{isToDashboard: whiteBackground}">
      @if (innerWidth >= 750) {
      <div
        class="categoriesFilter"
        [ngClass]="{isToDashboard: whiteBackground }"
      >
        @for (category of categories; track $index) {
        <p
          class="categories_tab"
          [ngClass]="{categorySelected: categoryIdSelected === category.id}"
          (click)="filterExampleTasks(category.id)"
        >
          {{ category.name | translate }}
        </p>
        }
      </div>
      }

      <div class="drop_down_container">
        @if (innerWidth < 750) {
        <div
          class="categoriesFilter"
          [ngClass]="{isToDashboard: whiteBackground }"
        >
          @if (!isFetchingTasks || headlineIsLoaded) {
          <select
            [(ngModel)]="categoryIdSelected"
            (change)="filterExampleTasks(categoryIdSelected)"
          >
            @for (category of categories; track $index) {
            <option [ngValue]="category.id">
              {{ category.name | translate }}
            </option>
            }
          </select>
          }
        </div>
        }
      </div>
    </div>
  </div>
  }

  <!--  loading state -->
  @if (isFetchingTasks && !headlineIsLoaded) {
  <div
    class="categoriesFilter_container max_width_page_component"
    [ngClass]="{ center_categories: isLandingPage, max_width_page_component: isToDashboard}"
  >
    <div class="filler"></div>
    <div class="categoriesFilter" [ngClass]="{isToDashboard: whiteBackground }">
      <p class="categories_tab isLoading"></p>
      <p class="categories_tab isLoading"></p>
      <p class="categories_tab isLoading"></p>
      <p class="categories_tab isLoading"></p>
    </div>
    <div class="filler"></div>
  </div>
  } @if (isLandingPage) {
  <div class="categories_sub_text">
    <p>{{ 'PAGES.LANDING.TASK_CAROUSEL_SUBTEXT' | translate }}</p>
  </div>
  }
  <div class="carousel_wrapper">
    <owl-carousel-o [options]="CustomOptionsTask">
      <ng-template carouselSlide>
        <div class="elements_wrapper">
          @if (isFetchingTasks) {
          <article class="isLoading loading_card"></article>
          <article class="isLoading loading_card"></article>
          <article class="isLoading loading_card"></article>
          <article class="isLoading loading_card"></article>
          } @for (task of tasksFirstHalf; track $index) {
          <article
            class="task_ex_container"
            (mousedown)="onMouseDown()"
            (mouseup)="onMouseUp()"
            (click)="goToTask(task)"
            [@changeTransition]
          >
            <div class="info_container">
              <div class="img_name">
                <img
                  ngSrc="{{task.user?.profileImg || placeholderImage }}"
                  (error)="onImgError($event)"
                  width="50"
                  height="50"
                  loading="lazy"
                  class="to_pb"
                  alt="Opgaveejer for løst opgaver online"
                />
                <div class="task_title_container">
                  <p class="category">{{ task?.headTag }}</p>
                  <p class="task_title">{{ task?.title }}</p>
                </div>
              </div>
            </div>
            <div class="review_price_container">
              <div class="five_star_review">
                <img
                  class="star_icon"
                  width="20"
                  height="20"
                  loading="lazy"
                  ngSrc="{{star || placeholderImage}}"
                />
                <p class="star_text">
                  5 {{ 'PAGES.LANDING.STARS' | translate }}
                </p>
              </div>
              <p class="price">{{ task?.budget | number }} kr</p>
            </div>
          </article>
          }
        </div>
      </ng-template>
      <ng-template carouselSlide> </ng-template>
    </owl-carousel-o>
    <owl-carousel-o [options]="CustomOptionsTask2">
      <!-------- for -------->
      <ng-template carouselSlide>
        <div class="elements_wrapper">
          @if (isFetchingTasks) {
          <article class="isLoading loading_card"></article>
          <article class="isLoading loading_card"></article>
          <article class="isLoading loading_card"></article>
          <article class="isLoading loading_card"></article>
          } @for (task of tasksSecondHalf; track $index) {
          <article
            class="task_ex_container"
            (mousedown)="onMouseDown()"
            (mouseup)="onMouseUp()"
            (click)="goToTask(task)"
            [@changeTransition]
          >
            <div class="info_container reverse">
              <div class="img_name reverse">
                @if (task.user?.profileImg) {
                <img
                  ngSrc="{{task.user?.profileImg || placeholderImage }}"
                  width="50"
                  height="50"
                  loading="lazy"
                  (error)="onImgError($event)"
                  class="to_pb"
                  alt="Opgaveejer for løst opgaver online"
                />
                }

                <div class="task_title_container">
                  <p class="category reverse">{{ task?.headTag }}</p>
                  <p class="task_title">{{ task?.title }}</p>
                </div>
              </div>
            </div>
            <div class="review_price_container">
              <p class="price reverse">{{ task?.budget | number }} kr</p>
              <div class="five_star_review reverse">
                <img
                  class="star_icon"
                  width="20"
                  height="20"
                  loading="lazy"
                  ngSrc="{{star || placeholderImage}}"
                />
                <p class="star_text reverse">
                  5 {{ 'PAGES.LANDING.STARS' | translate }}
                </p>
              </div>
            </div>
          </article>
          }
        </div>
      </ng-template>
      <ng-template carouselSlide> </ng-template>
    </owl-carousel-o>
  </div>
  @if (!noTitle && !noButton) {
  <div>
    <button
      class="handyhand_button blue_button task_ex"
      (click)="externalService.goToCreateTask()"
    >
      {{ 'NAVIGATION.BTN.CREATE_TASK' | translate }}
    </button>
  </div>
  }
</section>
