import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TaskService } from '@services/http/task.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { environment } from '../../../../environments/handyhand/environment.dk';

import { SlugifyPipe } from '@app/pipes/slugify.pipe';
import { TranslatePipe } from '@app/pipes/translate.pipe';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonModule } from '@angular/common';
import {
  CustomOptionsTask,
  CustomOptionsTask2,
} from '@app/pages/landing-page/OwlTaskOptions/CustomOwlTaskOptions';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

import { CarouselTask } from '@app/pages/landing-page/interfaces/carouselTask.type.interface';
import { ExternalService } from '@app/services/http/external.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    host: { ngSkipHydration: 'true' },
    imports: [
        CommonModule,
        TranslatePipe,
        CarouselModule,
        MatIconModule,
        NgOptimizedImage,
        FormsModule,
    ],
    providers: [SlugifyPipe, TaskService, TranslatePipe, ExternalService],
    selector: 'app-task-examples-lp',
    templateUrl: './taskExamples-lp.html',
    styleUrls: ['./taskExamples-lp.scss'],
    animations: [
        trigger('changeTransition', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition('void => *', [animate('0.5s 0.5s ease-in')]),
            transition('* => void', [animate('0.5s ease-in')]),
        ]),
    ]
})
export class TaskExamplesLp implements OnInit, OnDestroy {
  @Input() noTitle?: boolean;
  @Input() isToDashboard?: boolean;
  @Input() whiteBackground?: boolean;
  @Input() noButton?: boolean = false;
  @Input() isLandingPage?: boolean = false;
  public isBrowser: boolean = false;
  private destroy$ = new Subject<void>();
  public tasksFirstHalf: CarouselTask[] = [];
  public tasksSecondHalf: CarouselTask[] = [];
  public star = '/assets/svg/star_vector.svg';
  public innerWidth: number = 0;
  protected readonly CustomOptionsTask2 = CustomOptionsTask2;
  protected readonly CustomOptionsTask = CustomOptionsTask;
  public readonly placeholderImage = '/assets/svg/logo.svg';
  isFetchingTasks: boolean = false;
  mouseDownTime: number = 0;
  allowClick: boolean = true;
  headlineIsLoaded = false;

  public categories: { id: number; name: string }[] = [
    { id: 4, name: 'PAGES.LANDING.TASK_CATEGORY_4' },
    { id: 3, name: 'PAGES.LANDING.TASK_CATEGORY_3' },
    { id: 2, name: 'PAGES.LANDING.TASK_CATEGORY_2' },
    { id: 1, name: 'PAGES.LANDING.TASK_CATEGORY_1' },
  ];

  constructor(
    private taskService: TaskService,
    private slugifyPipe: SlugifyPipe,
    @Inject(PLATFORM_ID) private platformId: Object,
    public externalService: ExternalService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  @HostListener('window:resize', ['$event'])
  onResize = () => {
    if (this.isBrowser) {
      this.innerWidth = window.innerWidth;
    }
  };

  categoryIdSelected = 4;

  ngOnInit() {
    if (this.isBrowser) {
      this.innerWidth = window.innerWidth;
    }
    this.getExampleTasks();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getExampleTasks(mainTagId?: number) {
    this.isFetchingTasks = true;
    this.taskService
      .getExampleTasks(mainTagId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.length <= 5) {
            this.tasksFirstHalf = res;
            this.tasksSecondHalf = [];
          } else {
            this.tasksFirstHalf = res.slice(0, Math.round(res.length / 2));
            this.tasksSecondHalf = res.slice(Math.round(res.length / 2));
          }
          this.isFetchingTasks = false;
        },
        () => {
          this.isFetchingTasks = false;
        },
      );
    this.headlineIsLoaded = true;
  }

  filterExampleTasks(mainTagId: number) {
    this.categoryIdSelected = mainTagId;
    this.getExampleTasks(mainTagId);
  }

  onImgError(event: any) {
    event.target.src = `${environment.cloudFrontImgUrl}/private/compressed/Group.svg`;
  }

  onMouseDown() {
    this.mouseDownTime = new Date().getTime();
    this.allowClick = true;
  }

  onMouseUp() {
    const mouseUpTime = new Date().getTime();
    const holdDuration = mouseUpTime - this.mouseDownTime;

    const holdThreshold = 100; // 100 milliseconds

    if (holdDuration >= holdThreshold) {
      this.allowClick = false;
    }
  }

  goToTask(task: any) {
    if (!this.allowClick) {
      this.allowClick = true; // Reset the flag for the next click
      return;
    }
    this.externalService.goToTask({
      taskId: task.id,
      title: task.title,
      city: task.city,
    });
  }
}
