import { Component } from '@angular/core';
import { environment } from '../../../../environments/handyhand/environment.dk';
import { Config } from '@app/config/config';

import { Router } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@app/pipes/translate.pipe';

@Component({
    imports: [NgOptimizedImage, TranslatePipe, RouterLink],
    selector: 'app-service-options',
    templateUrl: './serviceOptionsComponent.html',
    styleUrls: ['./serviceOptionsComponent.scss']
})
export class ServiceOptionsComponent {
  private serviceIconPath = 'private/compressed/service-icons';
  constructor(
    private t: TranslatePipe,
    private router: Router,
  ) {}
  public services: { src: string; translationKey: string; img: string }[] = [
    {
      src: '/handyman/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.HANDYMAN',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/001-driller.svg`,
    },
    {
      src: '/have/havearbejde/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.GARDENING',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/042-gardening-tools.svg`,
    },
    {
      src: '/toemrer/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.CARPENTER',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/A004-saw.svg`,
    },
    {
      src: '/vvs-arbejde/vvs/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.PLUMBING',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/A036-plunger.svg`,
    },
    {
      src: '/maler/hh',
      translationKey: this.t.transform('PAGES.LANDING.SERVICE_OPTIONS.PAINTER'),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/A032-paint-roller.svg`,
    },
    {
      src: '/affald/affald-service/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.WASTE_COLLECTION',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/031-truck.svg`,
    },
    {
      src: '/elektriker/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.ELECTRICIAN',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/A033-flash.svg`,
    },
    {
      src: '/it/it-support/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.TECHNICIAN',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/A043-test.svg`,
    },
    {
      src: '/flytning/flyttemand/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.MOVING_HELP',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/031-truck.svg`,
    },
    {
      src: '/moebler/moebel-montoer/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.FURNITURE_ASSEMBLY',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/034-cupboard.svg`,
    },
    {
      src: '/rengoering/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.CLEANING',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/002-cleaning.svg`,
    },
    {
      src: '/rengoering/vinduespudser/hh',
      translationKey: this.t.transform(
        'PAGES.LANDING.SERVICE_OPTIONS.WINDOW_CLEANING',
      ),
      img: `${environment.cloudFrontImgUrl}/${this.serviceIconPath}/A054-layers.svg`,
    },
  ];

  public navigateToCategories() {
    return this.router.navigateByUrl('/kategorier');
  }
  protected readonly environment = environment;
  protected readonly Config = Config;
}
