import { OwlOptions } from 'ngx-owl-carousel-o';

export const CustomOptionsTask: OwlOptions = {
  items: 2,
  loop: true,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  navText: ['', ''],
  dots: false,
  center: true,
  slideTransition: 'linear',
  autoplayTimeout: 1000,
  autoplaySpeed: 20000,
  autoplayHoverPause: true,
  autoplay: true,
};

export const CustomOptionsTask2: OwlOptions = {
  items: 2,
  loop: true,
  mouseDrag: false,
  navText: ['', ''],
  rtl: true,
  touchDrag: false,
  pullDrag: false,
  dots: false,
  center: true,
  slideTransition: 'linear',
  autoplayTimeout: 1000,
  autoplaySpeed: 20000,
  autoplayHoverPause: true,
  autoplay: true,
};
