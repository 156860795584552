import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/*@Injectable({ providedIn: 'root' })*/
export class StateService {
  /*  // Used to toggle a 'waiting state' that the platform is in
  toggleWaitingComponentSource = new Subject<boolean>();

  toggleWaitingPage(status: boolean) {
    this.toggleWaitingComponentSource.next(status);
  }*/
}
