import { Pipe, PipeTransform } from '@angular/core';
import slugify from 'slugify';

@Pipe({ name: 'slugify', pure: true, standalone: true })
export class SlugifyPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return slugify(value, {
      lower: true,
      remove: /[*+~.()'"!:@/]/g,
      locale: 'da',
    });
  }
}
