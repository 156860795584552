<div class="ext_hold">
  <div class="outer_seo_page_component_container">
    <section
      class="meet_handyhands_container inner_seo_page_component_container"
    >
      <div class="meet_hh_1">
        <div class="inner nr2">
          <h2 class="title">
            {{ 'PAGES.LANDING.OWN_BOSS' | translate }}
          </h2>

          <p>
            {{ 'PAGES.LANDING.OWN_BOSS_DESC' | translate }}
          </p>

          <div class="usp_container">
            <div class="usp_wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  d="M7.93848 10.2512L11.3244 14.991C11.4638 15.1861 11.6461 15.3467 11.8572 15.4604C12.0684 15.574 12.3028 15.6378 12.5424 15.6467C12.782 15.6557 13.0205 15.6095 13.2396 15.5119C13.4586 15.4142 13.6523 15.2677 13.8058 15.0835L24.8991 1.7699"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div class="head_wrapper">
                <p>{{ 'PAGES.LANDING.OWN_BOSS_USP_1' | translate }}</p>
              </div>
            </div>

            <div class="usp_wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  d="M7.93848 10.2512L11.3244 14.991C11.4638 15.1861 11.6461 15.3467 11.8572 15.4604C12.0684 15.574 12.3028 15.6378 12.5424 15.6467C12.782 15.6557 13.0205 15.6095 13.2396 15.5119C13.4586 15.4142 13.6523 15.2677 13.8058 15.0835L24.8991 1.7699"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div class="head_wrapper">
                <p>
                  {{ 'PAGES.LANDING.OWN_BOSS_USP_2' | translate }}
                </p>
              </div>
            </div>

            <div class="usp_wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  d="M7.93848 10.2512L11.3244 14.991C11.4638 15.1861 11.6461 15.3467 11.8572 15.4604C12.0684 15.574 12.3028 15.6378 12.5424 15.6467C12.782 15.6557 13.0205 15.6095 13.2396 15.5119C13.4586 15.4142 13.6523 15.2677 13.8058 15.0835L24.8991 1.7699"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div class="head_wrapper">
                <p>{{ 'PAGES.LANDING.OWN_BOSS_USP_3' | translate }}</p>
              </div>
            </div>

            <div class="button_div">
              <app-generic-button-with-arrow
                [color_string]="'more_info_button'"
                [label]="'PAGES.LANDING.EARN' | translate"
                (onClick)="router.navigate(['findarbejde'])"
              ></app-generic-button-with-arrow>
            </div>
          </div>
        </div>

        <div class="handyhand_img_container">
          <img
            [width]="556"
            [height]="632"
            loading="lazy"
            [ngSrc]="'/assets/svg/user/own-boss.svg'"
            alt="handyman"
          />
        </div>
      </div>

      <div class="meet_hh_container">
        <div class="title_outer_container">
          <div class="user-title">
            <h2>{{ 'PAGES.LANDING.COMMUNITY_1' | translate }}</h2>

            <p>{{ 'PAGES.LANDING.COMMUNITY_2' | translate }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="carousel_container">
      <div class="absolute_carousel_container">
        <div class="absolute_carousel">
          @for (hh of handyhanders; track $index) {
            <div>
              <article class="hh_wrapper" [class.first_element]="$first">
                @if (!isMobile) {
                  <div class="hhImg">
                    <img
                      [width]="270"
                      [height]="270"
                      loading="lazy"
                      [ngSrc]="hh.imageSrc || placeholderImage"
                      class="actual_pp_img"
                      [alt]="hh.name || 'Handyhands profile picture'"
                    />
                  </div>
                }

                <div class="info_section_pp">
                  <div class="head_of_hh_card">
                    @if (isMobile) {
                      <div class="hhImg">
                        <img
                          [width]="270"
                          [height]="270"
                          loading="lazy"
                          [ngSrc]="hh.imageSrc || placeholderImage"
                          class="actual_pp_img"
                          [alt]="hh.name || 'Handyhands profile picture'"
                        />
                      </div>
                    }

                    <div class="name_and_trust">
                      <div>
                        <p class="name">{{ hh.name }}</p>
                      </div>

                      <div class="trustGroup">
                        <div class="trust_inner_container">
                          <div class="stardiv rates_number_container">
                            <p class="number_p">{{ hh.trustRating }}</p>

                            <img
                              class="star"
                              [width]="20"
                              [height]="20"
                              loading="lazy"
                              [ngSrc]="starIcon"
                              alt="Rating star"
                            />
                          </div>

                          <div class="rates_text_container">
                            <p class="minor_headline">
                              {{ 'PAGES.VERTICAL.REVIEWS' | translate }}
                            </p>

                            <div class="review_text">
                              @if (!isMobile) {
                                <span class="review_span based_on">{{
                                  'PAGES.HH_LANDING.BASED_ON' | translate
                                }}</span>
                              }

                              <span class="review_span">{{
                                hh.reviewGiven
                              }}</span>

                              <span class="review_span">{{
                                'PAGES.VERTICAL.REVIEWS' | translate
                              }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="trust_inner_container">
                          <div class="rates_number_container">
                            <p class="number_p">{{ hh.completionRate }}%</p>
                          </div>

                          <div class="rates_text_container">
                            <p class="minor_headline">
                              {{ 'GENERAL.COMPLETION_RATE' | translate }}
                            </p>

                            <div class="review_text">
                              @if (!isMobile) {
                                <span class="review_span based_on">{{
                                  'PAGES.HH_LANDING.BASED_ON' | translate
                                }}</span>
                              }

                              <span class="review_span">{{
                                hh.reviewReceived
                              }}</span>

                              <span class="review_span">{{
                                'PAGES.VERTICAL.REVIEWS' | translate
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="specialities_container">
                    <p class="specialities">
                      {{ hh.specialityTranslationKey | translate }}
                    </p>
                  </div>

                  <div class="description">
                    <p>{{ hh.userDescriptionKey | translate }}</p>
                  </div>

                  <div class="profile_traits_container">
                    <div class="profile_traits">
                      @for (badge of hh.badges; track $index) {
                        <div class="badge_container">
                          <img
                            class="badge"
                            [width]="21"
                            [height]="21"
                            loading="lazy"
                            [ngSrc]="badge.src"
                            [alt]="badge.alt"
                          />

                          <p>{{ badge.alt }}</p>
                        </div>
                      }
                    </div>

                    <div class="profile_traits first">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="18"
                          viewBox="0 0 25 18"
                          fill="none"
                        >
                          <path
                            d="M9.11719 2.7168C8.10742 3.25391 7.30176 3.72656 6.7002 4.13477C6.09863 4.54297 5.59375 5.05859 5.18555 5.68164C4.79883 6.30469 4.60547 7.05664 4.60547 7.9375C4.92773 8.41016 5.30371 8.78613 5.7334 9.06543C6.18457 9.34473 6.73242 9.53809 7.37695 9.64551C9.63281 10.0537 10.7607 11.2676 10.7607 13.2871C10.7607 14.4902 10.3418 15.4893 9.50391 16.2842C8.66602 17.0791 7.58105 17.4766 6.24902 17.4766C5.11035 17.4766 4.10059 17.1973 3.21973 16.6387C2.33887 16.0801 1.65137 15.2959 1.15723 14.2861C0.68457 13.2764 0.448242 12.127 0.448242 10.8379C0.448242 9.26953 0.791992 7.7334 1.47949 6.22949C2.18848 4.7041 3.20898 3.4043 4.54102 2.33008C5.87305 1.25586 7.43066 0.557617 9.21387 0.235352C9.81543 0.729492 10.2021 1.33105 10.374 2.04004L9.11719 2.7168ZM22.8135 2.7168C21.8037 3.25391 20.998 3.72656 20.3965 4.13477C19.7949 4.54297 19.29 5.05859 18.8818 5.68164C18.4951 6.30469 18.3018 7.05664 18.3018 7.9375C18.9033 8.86133 19.8271 9.43066 21.0732 9.64551C23.3076 10.0537 24.4248 11.2676 24.4248 13.2871C24.4248 14.4902 24.0059 15.4893 23.168 16.2842C22.3516 17.0791 21.2773 17.4766 19.9453 17.4766C18.8066 17.4766 17.7969 17.1973 16.916 16.6387C16.0352 16.0801 15.3477 15.2959 14.8535 14.2861C14.3809 13.2764 14.1445 12.127 14.1445 10.8379C14.1445 9.26953 14.4883 7.7334 15.1758 6.22949C15.8848 4.7041 16.9053 3.4043 18.2373 2.33008C19.5693 1.25586 21.127 0.557617 22.9102 0.235352C23.5117 0.729492 23.8984 1.33105 24.0703 2.04004L22.8135 2.7168Z"
                            fill="#1B9AC6"
                          />
                        </svg>

                        <p class="review">
                          {{ hh.reviewTranslationKey2 | translate }}
                        </p>

                        <p class="review_giver">-{{ hh.reviewGiver }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          }
        </div>
      </div>

      <div class="blue_backdrop"></div>
    </section>
  </div>
</div>
